.signup-container {
    background-color: rgba(220, 229, 223, 0.8); /* Same modal background */
    padding: 40px;
    border-radius: 40px; /* Rounded corners */
    max-width: 60%;
    margin: auto;
    text-align: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .stay-loop-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; /* Gap between text, image, and the email signup */
  }
  
  .signpost-image {
    width: 65%; /* Adjust as necessary */
    margin: 20px 30px auto; /* Center image with bottom margin */
  }

  .message {
    font-size: 20px;
    font-weight: 600;
    color: var(--primary-color);
  }
  
  .email-signup {
    display: flex;
    width: 95%;
    justify-content: center;
    gap: 20px; /* Space between input and button */
    margin-top: 10px;
  }
  
    /* Email Input Field Styling */
    .email-signup input {
        font-family: 'Merriweather', serif; /* Set the font */
        font-size: 16px; /* Font size */
        line-height: 16px; /* Line height */
        letter-spacing: 0.5px; /* Letter spacing */
        color: var(--on-surface-variant); /* Text color inside the input */
        padding: 20px 15px; /* Padding inside the input */
        border: 3px solid var(--primary-color); /* 3px border in the primary color */
        border-radius: 8px; /* 4px border radius */
        background-color: var(--surface-container-highest); /* Input background color */
        width: 70%; /* Full width */
        box-sizing: border-box; /* Include padding and border in element's width and height */
    }
  
  /* Add a focus effect if needed */
  .email-signup input:focus {
    outline: none; /* Remove default focus outline */
    border-color: var(--primary-10); /* Change border color on focus */
  }
  
  .email-signup button {
    cursor: pointer;
  }
  
  .divider {
    border: 4px solid var(--primary-color);
    margin: 40px 0;
    border-radius: 15px;
  }
  
  .private-beta-section {
    text-align: center;
    display: flex;
    flex-direction: column; /* Stack the elements vertically */
    align-items: center; /* Center align horizontally */
    text-align: left;
    gap: 30px; /* Now gap will work since flex is set */
}
  
  .button-patreon {
    background-color: var(--primary-color);
    border: none;
    padding: 20px 35px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
    border-radius: 15px; /* 15px rounded corners for Patreon button */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    box-shadow: 0px 0px 20px 14px rgba(255, 255, 255, 1); /* White glow with spread */
    transition: background-color 0.1s ease, box-shadow 0.1s ease; /* Smooth transitions */
  }

  /* Hover Effect for Button */
.button-patreon:hover {
    background-color: var(--primary-20); /* Change to primary color on hover */
}

/* Active (Click) Effect */
.button-patreon:active {
    background-color: var(--primary-10); /* Change to primary color on click */
}

  .patreon-icon {
    width: 25px;
    height: auto;
  }
  
  .signup-body {
    color: var(--primary-20);
  }

  @media (max-width: 1100px) {
    .signup-container {
        max-width: 80%;
      }
    .email-signup input {
        width: 95%; 
    }
  }

  @media (max-width: 768px) {
    .signup-container {
      max-width: 90%;
      padding: 20px;
      margin-bottom: 30px;
    }
  
    .email-signup {
      flex-direction: column;
      width: 90%;
    }
  
    .email-signup input {
      width: 100%;
    }
  
    .button-primary.mobile-button {
      width: 90%;
    }

    .button-patreon {
        padding: 15px 20px;
        font-size: 14px;
      }
    .signup-body {
      font-size: 14px;
    }
  }