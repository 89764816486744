/* index.css */
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;700&family=Merriweather:wght@300;400;700&family=Montserrat:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons+Outlined');

/* Global Resets */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
  }
  
/* Color Variables */
:root {
    --primary-color: #1A5141;
    --primary-20: #00382A;
    --primary-10: #082017;
    --primary-container: #417664;
    --secondary-color: #87512C;
    --background-color: #F8FAF7;
    --error-color: #BA1A1A;
    --text-primary-color: #FFFFFF;
    --text-secondary-color: #FFFFFF;
    --on-surface-color: #191C1B;
    --on-surface-variant: #404945;
    --surface-container-highest: #e1e3e0;
    --tertiary-70: #E29C56;
    --tertiary-60: #E3833E;
}

/* Body and Global Styles */
body {
    background-color: #A0C1A8; 
    color: #FFFFFF; 
    font-family: 'Cinzel', 'Merriweather', 'Montserrat', sans-serif; 
    line-height: 1.5; 
}

/* Typography Styles */
.mainTitle {
    font-family: 'Cinzel', serif; 
    font-weight: 700; 
    font-size: 70px; 
    line-height: 64px;
    letter-spacing: -0.25px; 
    color: var(--primary-color);
    text-shadow: 2px 2px 3px rgba(255, 255, 255, 1);
}


.displayLarge {
    font-family: 'Cinzel', serif; 
    font-weight: 600; 
    font-size: 57px; 
    line-height: 64px;
    letter-spacing: -0.25px; 
    color: var(--primary-color);
    margin: 20px;
}

.CTAtext {
    font-family: 'Cinzel', serif; 
    font-weight: 400; 
    font-size: 30px; 
    line-height: 36px;
    color: var(--on-surface-color);
    margin-bottom: 21px;
}

.subtitleText {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 26px;
    line-height: 34px;
    letter-spacing: 0.1px; 
    color: var(--primary-20); /* Primary color with 20% opacity */
    text-align: center;
    margin-top: 10px;
  }
  
  .supportingText {
    margin-top: 20px;
    font-family: 'Merriweather', serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: var(--primary-20);
    text-align: center;
}

.feature-body {
    font-family: 'Merriweather', serif;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: left;
    padding-left: 50px; 
    color: white;
  }

/* Global Container Styles for Centering Content */
.container-center {
    display: flex;
    flex-direction: column; /* Stack header and content vertically */
    align-items: center; /* Center-align content horizontally */
    justify-content: flex-start; /* Align content to top */
    max-width: 50%;
    box-sizing: border-box;
    margin: 0 auto; /* Center the container itself */
    padding: 2% 0; /* Default padding for desktop */
    gap: 45px; /* Vertical gap between items for desktop */
}

/* Default (Desktop) Logo Styling */
.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    overflow: hidden;
    text-decoration: none;
    background-color: transparent;
  }

.call-to-action {
    position: relative;
    width: 100%;
}

/* Background Image Styling */
.background-image {
    width: 60%; /* Default width for desktop */
    margin: auto auto; /* Center the image */
    display: block;
    animation: float 6s ease-in-out infinite;
}

@keyframes float {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-15px); /* Float upwards */
    }
    100% {
      transform: translateY(0px); /* Return to original position */
    }
  }

/* Modal Overlay Styling */
.modal-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(231,233,230, 0.8);
    backdrop-filter: blur(1.5px);
    padding: 30px 40px;
    border-radius: 28px;
    text-align: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  /* Button Styling with White Glow */
.button-primary {
    background-color: var(--tertiary-70);
    border: none;
    padding: 15px 30px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
    border-radius: 20px;
    cursor: pointer;
    /* Applying box-shadow with your Figma values */
    box-shadow: 0px 0px 20px 14px rgba(255, 255, 255, 1); /* White glow with spread */
    transition: background-color 0.1s ease, box-shadow 0.1s ease; /* Smooth transitions */
}

/* Hover Effect for Button */
.button-primary:hover {
    background-color: var(--tertiary-60); /* Change to primary color on hover */
}

/* Active (Click) Effect */
.button-primary:active {
    background-color: var(--primary-color); /* Change to primary color on click */
}

/* Nutshell Section */
.nutshell-section {
    width: 100%;
    text-align: center;
  }
  
  .nutshell-container {
    background-color: rgba(220, 229, 223, 0.8); /* Modal-like background */
    padding: 50px;
    border-radius: 40px; /* Rounded corners */
    max-width: 70%; /* Center the box and adjust width */
    margin: auto;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); /* Box shadow */
  }
  
  .insightContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .insightText {
    width: 65%;
    margin-top: 10px;
  }
  
  .insightImage {
    width: 28%;
    height: auto;
  }
  
  /* Mobile View Styling */
  .mobile-view {
    display: none;
  }
  
  .toggle-section {
    background-color: rgba(220, 229, 223);
    padding: 15px 10px;
    margin-bottom: 20px;
    border-radius: 20px;
  }
  
  .toggle-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    cursor: pointer;
  }
  
  .toggle-content {
    margin-top: 10px;
  }
  
  .toggle-content img {
    width: 80px;
    margin-top: 10px;
    display: block;
    margin: 0 auto;
  }

/* Responsive Design for Different Screen Sizes */

@media (max-width: 1700px) {
    .container-center {
        max-width: 90%;
        padding: 2% 10%; /* Adjust padding for mobile to 1/10th of screen width */
      }
}

@media (max-width: 1400px) {
    .container-center {
        max-width: 100%;
      }
}

@media (max-width: 1100px) {
    .container-center {
      padding: 5% 10%; /* Adjust padding for mobile to 1/10th of screen width */
      gap: 20px; /* Vertical gap between items for mobile */
    }
    .background-image {
        width: 75%;
        margin: 20px auto;
      }

    .nutshell-container {
        padding: 30px;
        max-width: 70%; /* Center the box and adjust width */
      }
    .subtitleText {
    font-size: 20px;
    line-height: 24px;
    }
    .insightText {
        width: 70%;
      }
    .supportingText {
    margin-top: 10px;
    font-weight: 300;
    font-size: 16px;
    }
  }

  
@media (max-width: 768px) {
  .container-center {
    padding: 5% 10%; /* Adjust padding for mobile to 1/10th of screen width */
    gap: 15px; /* Vertical gap between items for mobile */
  }
  .mainTitle {
    font-size: 45px; /* Adjust font size for small screens */
  }
  .logo {
    height: 40px;
    width: 40px;
  }
  .background-image {
    width: 80%;
    margin: 20px auto;
  }
  .modal-overlay.mobile {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    width: 90%; /* Adjust width for mobile */
    margin: 20px auto;
  }

  .CTAtext {
    font-size: 1.5rem; /* Adjust text size for mobile */
  }
  .displayLarge {
    font-size: 35px; 
    line-height: 150%;
}
  .desktop-view {
    display: none;
  }

  .mobile-view {
    display: block;
    padding: 20px;
    padding-top: 40px;
    max-width: 90%; /* Center the box and adjust width */
  }
  .subtitleText {
    font-size: 24px;
  }

  .supportingText {
    font-size: 16px;
  }
}

@media (max-width: 430px) {
    .container-center {
      padding: 5% 5%; /* Adjust padding for mobile to 1/10th of screen width */
      gap: 10px; /* Vertical gap between items for mobile */
    }
    .mainTitle {
      font-size: 35px; /* Adjust font size for small screens */
      text-shadow: 1px 1px 2px rgba(255, 255, 255, 1);

    }
    .background-image {
    width: 80%;
    margin: 10px auto;
    }
    .CTAtext {
        font-size: 1.2rem; /* Adjust text size for mobile */
        margin-bottom: 10px;
      }
    .button-primary {
        font-size: 14px;
        box-shadow: 0px 0px 8px 10px rgba(255, 255, 255, 1); /* White glow with spread */
    }
    .modal-overlay.mobile {
        padding: 15px 15px;
      }
    .displayLarge {
        font-size: 25px; 
    }
    .subtitleText {
        font-size: 20px;
    }
  }

