
/* Features Section Adjustments */
.features-container {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Vertically center the features */
    gap: 30px; /* 30px gap between features and the feature image */
  }
  
  .features-list {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Vertically center the list */
    gap: 40px; /* 40px vertical gap between the feature boxes */
  }
  
  .feature-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 25px 40px; /* 40px horizontal padding, 25px vertical padding */
    gap: 20px; /* 20px gap between the title and the body text */
    background-color: var(--primary-container); /* Default box background color */
    border-radius: 50px; /* 50px rounded corners */
    transition: background-color 0.3s ease; /* Smooth background transition */
    min-width: 550px;
  }
  
  .feature-box:hover {
    background-color: var(--primary-20); /* Hover state */
  }
  
  .feature-title {
    display: flex;
    align-items: center;
    gap: 10px; /* 10px horizontal gap between icon and text */
    font-family: 'Cinzel', serif;
    font-size: 22px;
    font-weight: 700;
    color: white;
  }
  
  .feature-image {
    width: 100%; 
    max-width: 600px;
    height: auto;
  }
  
  .feature-body ul {
    padding: 0;
    margin: 0;
  }
  
  .feature-body li {
    margin: 5px 0; /* Adds space between each list item */
  }
  
  /* Feature Image Container */
  .feature-image-container {
    position: relative;
    width: 100%;
    max-width: 600px; /* Adjust to your preference */
  }
  
  /* Overlay Feature Box */
  .overlay-feature-box {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px 30px;
    background-color: rgba(0, 56, 42, 0.9); /* 10% transparency */
    backdrop-filter: blur(2px); /* Adds subtle blur to the overlay */
    border-radius: 20px; /* Rounded corners for the feature box */
    color: white;
  }
  
/* Arrow Buttons */
.arrow-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--primary-color);
    border: none;
    color: white;
    /* font-size: 48px; */
    width: 80px;
    height: 80px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15); /* Drop shadow */
    transition: background-color 0.3s ease;
    z-index: 1; /* Ensure the arrows stay above the image */
  }
  
  .material-icons-outlined {
    font-size: 36px;
  }
  
  /* Position the left arrow slightly outside and overlapping the image */
  .left-arrow {
    left: -40px; /* Position it outside the left of the image */
  }
  
  /* Position the right arrow slightly outside and overlapping the image */
  .right-arrow {
    right: -40px; /* Position it outside the right of the image */
  }
  
  .arrow-button:hover {
    background-color: var(--primary-20);
  }
  
  /* Responsive Adjustments */
  @media (max-width: 1100px) {


    .overlay-feature-box {
      padding: 50px 30px;
      gap: 30px;
    }
  
    .feature-title {
      font-size: 18px;
    }
  }

  @media (max-width: 430px) {
    .arrow-button {
        width: 60px;
        height: 60px;
      }
      
      .material-icons-outlined {
        font-size: 24px;
      }

    .left-arrow {
        left: -10px; /* Position it outside the left of the image */
    }
    
    .right-arrow {
        right: -10px; /* Position it outside the right of the image */
    }

    .feature-image-container {
        display: flex;
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically (if needed) */
        width: 80%;
        margin: 0 auto; /* Ensure it's centered under the title */
    }
    .overlay-feature-box {
        padding: 20px 20px;
        border-radius: 10px;
      }

      .feature-title {
        font-size: 14px;
        font-weight: 700;
        color: white;
      }
      
      .feature-body {
        font-size: 12px; 
        padding-left: 30px;
      }
      
}