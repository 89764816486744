/* Menu Container Styles */
.menu-container {
    background-color: rgba(220, 229, 223, 0.8); /* Menu background color */
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    width: 300px; /* Set a fixed width for the menu */
  }
  
  /* Menu Header */
  .menu-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  /* Whander Icon */
  .whander-icon {
    width: 100px; /* Set a fixed width for the icon */
    height: 100px; /* Set a fixed height for the icon */
    border-radius: 50%; /* Make the image rounded */
    object-fit: cover; /* Ensure the image fits within the circle */
  }
  
  /* Whander Title */
  .whander-title {
    font-family: 'Cinzel', serif;
    font-weight: 700;
    font-size: 30px;
    color: var(--primary-20); /* Whander title color */
    margin-top: 10px;
  }
  
  /* Menu List Styles */
  .menu-list {
    flex-grow: 1;
    padding: 0;
    font-family: 'Cinzel', serif;
  }
  
  .menu-list-item {
    display: flex;
    align-items: center;
    padding: 15px;
    color: var(--primary-20); /* Menu item text color */
    font-family: 'Cinzel', serif;
    font-size: 16px;
    /* text-decoration: none; Remove underline from links */
    border-radius: 12px; /* Make list item rounded */
    transition: background-color 0.3s ease; /* Smooth transition */
  }
  
  .menu-list-item:hover {
    background-color: rgba(193, 204, 200, 0.5); /* Slightly lighter than background */
    border-radius: 12px; /* Rounded borders */
  }
  
  /* Menu List Text */
  .menu-list-text {
    color: var(--primary-20); /* Ensure the text color is inherited */
  }
  
  /* Disabled list item styling */
  .menu-list-item-disabled {
    color: var(--on-surface-variant); /* Make disabled items less prominent */
    cursor: not-allowed;
  }
  
  /* Menu List Icon Styles */
  .menu-list-icon {
    min-width: 40px; /* Increase icon size */
    color: var(--primary-20); /* Icon color */
    font-size: 28px; /* Increase icon size */
  }
  
  /* Social Icons at the Bottom */
  .menu-social-icons {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 20px;
  }
  